<template>
  <v-container class="pa-6">
    <v-row class="headLineRow ">

      <v-overflow-btn
          style="max-width: 450px"
          @change="setSorterSM('ASC')"
          v-model="sortCheckBox"
          class="my-2 align-self-end d-inline-flex"
          :items="headLines"
          label="Sortieren nach"
          counter
          item-value="text"
      ></v-overflow-btn>
      <a class="ps-4 d-inline-flex" @click="setSorter()">
        <v-icon :class="getClassSort( 'ASC')">mdi-arrow-up</v-icon>
        <v-icon :class="getClassSort('DESC')">mdi-arrow-down</v-icon>
      </a>
    </v-row>

    <!-- Headline Section Section end-->

    <!-- Rows start-->
    <div v-if="rows.length > 0" class="pt-8">
      <div
          :id="'row'+index"
          class="row customerRow detailCard  pa-md-1"
          v-for="(values, index) of rows" v-bind:key="index"
      >
        <div class="col-1" style="cursor: initial">
          <v-checkbox
              class="pa-0 ma-0"
              :input-value="false"
              v-model="checkedIds[values.id]"
              @click="setCheckedEntries()"
          >
          </v-checkbox>
        </div>

        <div class="col" @click="openDetail(values.id)">
          <h4 v-if="values.status === 'Erledigt'"><s>{{ values.subject }}</s></h4>
          <h4 v-else>{{ values.subject }}</h4>
          <p>

            <span v-if="values.prio">
              <v-icon v-if="values.prio === 'Hoch'">mdi-chevron-double-up</v-icon>
              <v-icon v-if="values.prio === 'Mittel'">mdi-equal</v-icon>
              <v-icon v-if="values.prio === 'Niedrig'">mdi-chevron-double-down</v-icon>

              {{ values.prio }}
            </span>
            <span v-if="values.date">
              <v-icon v-if="values.prio">mdi-circle-small</v-icon>
              <v-icon>mdi-calendar</v-icon>  {{ getStringDate(values.date) }}
            </span>
            <span v-if="values.description">
              <v-icon v-if="values.date || values.prio">mdi-circle-small</v-icon>
              {{ values.description }}
            </span>
          </p>
        </div>
        <v-tooltip top color="secondary">
          <template v-slot:activator="{ on, attrs }">
        <span
            v-bind="attrs"
            v-on="on"
        >

          <v-icon color="primary" class="col-1" v-if="values.status !== 'Erledigt'"
                  @click="$emit('setDone', {id: values.id})">mdi-check</v-icon>


        </span>
          </template>
          Als erledigt markieren
        </v-tooltip>

      </div>
    </div>
    <div v-else class="pt-8">
      <NoResultSnippet :text="textNoResults"></NoResultSnippet>
    </div>
    <!-- Rows end-->


    <!-- Delete Section Section start-->
    <div class="row editRow pt-6 ps-2 pb-2 mt-8 text-end" v-if="checkedIdList.length > 0">
      <div class="col">

        <span class="align-self-center pe-3" v-if="checkedIdList.length === 1">Einen Eintrag ausgewählt</span>
        <span class="align-self-center pe-3" v-if="checkedIdList.length > 1">{{ checkedIdList.length }} Einträge ausgewählt</span>

        <v-btn class="error" @click="sureToDeleteDialog=true">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <SureToDelete
        :dialog="sureToDeleteDialog"
        :count="checkedIdList.length"
        @cancel="sureToDeleteDialog = false"
        @sure="deleteEntries(); sureToDeleteDialog = false"
    ></SureToDelete>
    <!-- Delete Section Section end-->
  </v-container>
</template>

<script>
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
const NoResultSnippet = () => import("@/components/generalUI/NoResultSnippet");

export default {
  name: "TableView",
  data() {
    return {
      sureToDeleteDialog:false,
      ceckbox: {},
      currentSort: this.headLines[0].sort,
      sortStyle: 'ASC',
      checkedIds: {},
      checkedIdList: [],
      editDialog: false,
      sortCheckBox: ""
    }
  },
  components: {NoResultSnippet,SureToDelete},
  props: {
    /**
     *   List of the headlines
     */
    headLines: Array,
    /**
     *   List of list or objects
     */
    rows: Array,
    textNoResults: String
  },
  mounted() {
  },
  methods: {

    getClassesField(key) {
      switch (key) {
        default:
          return "col-12 col-md sm-customerField " + key
      }
    },
    getClassesHeadLine(key) {
      switch (key) {
        case "Nr.":
          return "col-1 headLineField " + key
        default:
          return "col headLineField " + key
      }
    },
    setSorterSM(sortStyle) {
      this.sortStyle = sortStyle
      for (let headLine of this.headLines) {
        if (headLine.text === this.sortCheckBox) {
          this.currentSort = headLine.sort
        }
      }
      this.$store.dispatch('sort/sort', {
        objectArray: this.rows,
        sortStyle: this.sortStyle,
        sortField: this.currentSort,
      })
    },
    setSortStyleSM() {
      if (this.sortStyle === 'DESC') {
        this.sortStyle = 'ASC'
      } else {
        this.sortStyle = 'DESC'
      }
      this.$store.dispatch('sort/sort', {
        objectArray: this.rows,
        sortStyle: this.sortStyle,
        sortField: this.currentSort,
      })
    },

    setSorter() {

      if (this.sortStyle === 'DESC') {
        this.sortStyle = 'ASC'
      } else this.sortStyle = 'DESC'

      for (let headLine of this.headLines) {
        if (headLine.text === this.sortCheckBox) {
          this.currentSort = headLine.sort
        }
      }
      this.$store.dispatch('sort/sort', {
        objectArray: this.rows,
        sortStyle: this.sortStyle,
        sortField: this.currentSort,
      })

    },
    getClassSort(sortStyle) {
      if (sortStyle === this.sortStyle) {
        return 'primary--text scaled'
      } else {
        return 'lightgrey--text '
      }
    },
    getStringDate(date) {
      if (date !== null) {
        const newDate = new Date(date)
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return newDate.toLocaleDateString('de-DE', options)
      } else {
        return ""
      }
    },
    setCheckedEntries() {
      let checkedIds = []
      for (let [id, check] of Object.entries(this.checkedIds)) {
        if (check) {
          checkedIds.push(id)
        }
      }
      this.checkedIdList = checkedIds
    },
    deleteEntries() {
      this.$emit("deleteEntries", this.checkedIdList)
      this.checkedIdList = []
      this.checkedIds = {}
    },
    editCustomers() {
      this.$emit("editCustomers", this.checkedIdList)
      this.checkedIdList = []
      this.checkedIds = {}
    },
    openDetail(id) {
      this.$emit("openDetail", id)
    },
    openEdit(id) {
      this.$emit("openEdit", id)
    }
  }
}

</script>


<style scoped>

.customerRow {
  border-top: dotted 1px #F1E7E2;
  border-bottom: dotted 1px #F1E7E2;
  transition: 0.4s;
}

.customerRow:hover {
  transform: scale(1.01);
  transition: 0.4s;
  background-color: #F3F4F3;
  cursor: pointer;
}

.customerField {
  border-left: dotted 1px #F1E7E2;
  border-right: dotted 1px #F1E7E2;
  overflow: hidden;
}

.headLineField {
  overflow: hidden;
}

.headLineRow {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}

.editRow {
  position: sticky;
  background-color: white;
  bottom: 0;
  z-index: 2;
}


.scaled {
  transform: scale(1.4);
}

</style>
